import React, { useEffect } from 'react';
import './CardStyles.css';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import usa from "./images/usa1.jpg";
import uk from "./images/uk.jpg";
import canada from "./images/canada.jpg";
import Australia from "./images/australia.jpg";
import Europe from "./images/europe.jpg";
import newzealand from "./images/new-zealand.jpg";

const countries = [
  {
    name: 'USA',
    info: 'A global leader in technology and finance, the USA hosts numerous multinational corporations driving innovation.',
    image: usa,
    path: "/usa",
  },
  {
    name: 'UK',
    info: 'The UK is a vibrant hub for overseas businesses, especially in finance and creative industries, with London at its center.',
    image: uk,
    path: '/uk',
  },
  {
    name: 'Canada',
    info: 'Canada attracts foreign investment in natural resources and technology, fostering a dynamic business environment.',
    image: canada,
    path: '/canada',
  },
  {
    name: 'Australia',
    info: 'Known for its strong economy, Australia is a strategic base for overseas companies in mining and finance.',
    image: Australia,
    path: '/australia',
  },
  {
    name: 'Europe',
    info: 'A diverse and culturally rich continent, Europe attracts multinational companies with its strong economy and skilled workforce.',
    image: Europe,
    path: '/europe',
  },
  {
    name: 'New Zealand',
    info: 'Renowned for its stunning landscapes and innovation, New Zealand fosters a welcoming environment for overseas businesses in various sectors.',
    image: newzealand,
    path: '/new-zealand',
  },
];

const shoot = () => {
  window.scrollTo(0, 0);
};

const Card = ({ name, info, image, path, animation }) => {
  return (
    <div className="card" data-aos={animation}>
      <div className="card-inner">
        <div className="card-front">
          <img src={image} alt={name} className="card-image" />
          <h3 className="card-title">{name}</h3>
        </div>
        <div className="card-back">
          <h3>{name}</h3>
          <p>{info}</p>
        </div>
      </div>
      <Link to={path}>
        <button className='card-back-button' onClick={shoot}>Discover</button>
      </Link>
    </div>
  );
};

const CardContainer = () => {
  useEffect(() => {
    AOS.init({ duration: 400 });
  }, []);

  return (
    <div className='cards-main-div'>
      <h1 className='card-heading'>choose your <span>dream destination</span></h1>
      <p className='card-heading-para'>Empowering Students to Achieve Global Dreams.</p>
      <div className="card-container">
        {countries.map((country, index) => {
          let animation = '';
          if (index % 3 === 0) animation = 'fade-left';  // Left column
          else if (index % 3 === 1) animation = 'fade-up'; // Center column
          else animation = 'fade-right';  // Right column

          return (
            <Card
              key={index}
              name={country.name}
              info={country.info}
              image={country.image}
              path={country.path}
              animation={animation} // Pass animation type to Card
            />
          );
        })}
      </div>
    </div>
  );
};

export default CardContainer;
