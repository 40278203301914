
import React from 'react';
import './Aus.css';
import usa from "./images/australia.jpg";
import Contact from './Contact';
// import usabanar from "./images/conban2.jpg"
// import ContactPage from './ContactPage';

const AUS = () => {
    return (
        <>
            <div className="country-container">
                <div className="aus-banner">
                    {/* <img className='aus-banar-img' src={usabanar}></img> */}
                    <h1 className='aus-banar-name'>AUSTRALIA</h1>
                </div>
                <div className="country-info">
                    <h1 className='aus-tag'>WELCOME TO  <span>AUSTRALIA</span></h1>
                    <p className='aus-para'>
Australia attracts many overseas companies, especially in mining, agriculture, and finance. Its diverse market and high quality of life appeal to international businesses seeking growth opportunities. The country’s regulatory environment supports innovation and investment.

                     </p>
                </div>
                <div className='aus-info'>
                    <h1 className='aus-info-tag'>Study in AUSTRALIA</h1>
                    <ul>
                        <li>AUS is an educational hub with over 4500 institutions offering undergraduate, postgraduate and doctorate degrees </li>
                        <li>Degrees which pack world-recognition at the most elevated level</li>
                        <li>Excellent support system for international students</li>
                        <li> AUS provides phenomenally higher returns over a student's investment. AUS education is the best investment for the future</li>
                        <li> AUS pride themselves on being at the forefront of technology, research and techniques as they make the best possible equipment and resources available to their students.</li>
                        <li> A preference for extrinsic workforce from multicultural backgrounds.</li>
                        <li> Affordable tuition fees ranging from $15,000 and $60,000 per annum.</li>
                        <li> Offers financial help in the form of scholarships, emphasis on internships & career advancement</li>
                        <li> Since the AUS lives in the lap of technological advancement and industrial as well as sectoral growth, obtaining employment opportunities is a matter of just finding the right one!</li>
                        <li> One can easily overcome culture shock in the AUS and as you look around, you’ll see you’re not the only newcomer in town</li>
                    </ul>
                </div>

                <div className='aus-img-info'>
                    <div className='aus-left-img-part'>
                        <img className='aus-down-img' src={usa}></img>
                    </div>
                    <div className='aus-right-info-part'>
                        <h1 className='about-head-aus'><span>REWA</span> Overseas Educational Consultants</h1>
                        <p className='about-para1-aus'>Aim High - Work with a Vision - Stay Focused - Achieve Your Dream - And be a Luminary to Many</p>
                        <p className='about-para2-aus'>REWA Overseas Educational Consultants is the one-stop solution for all your Study Abroad requirements. Our core activity is to offer comprehensive services to students and professionals from all parts of India, who wish to pursue their higher education in Foreign countries like USA, UK, Canada, Australia, Europe, New zealand and so on…</p>

                    </div>


                </div>
            </div>
            <Contact/>
        </>
    );
};

export default AUS;
