
import './App.css';
import { BrowserRouter, Routes, Route,Navigate ,useLocation} from "react-router-dom";

import Navbar from './Navbar';
import Homepage from './HomePage';
// import ContactPage from './ContactPage';
import ContactUS from './ContactUS';
import USA from './Usa';
import UK from './Uk';
import CANADA from './Canada';
import Footer from './Footer';
import EUROPE from './Europe';
import AUS from './Aus';
import Newzealand from './Newzealand';
import Ourservices from './Ourservices';

import React from "react";

function App() {
 

  return (
    <div>
      < BrowserRouter>
      <Navbar/>

      <Routes>
        <Route>
          <Route path="/" element={<Homepage />} />
          <Route path="/contact" element={<ContactUS />} />
          <Route path="/about" element={<Ourservices />} />
          <Route path="/usa" element={<USA/>} />
          <Route path="/uk" element={<UK/>} />
          <Route path="/canada" element={<CANADA/>} />
          <Route path="/europe" element={<EUROPE/>} />
          <Route path="/australia" element={<AUS/>} />
          <Route path="/new-zealand" element={<Newzealand/>} />
         



          {/* <Route path="/about" element={<About />} /> */}
          {/* <Route path="/contactus" element={<ContactUs/>} /> */}
          
        </Route>
      </Routes>
      <Footer/>
      </BrowserRouter>
    </div>
  );
}

export default App;
