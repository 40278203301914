 import React from 'react';
import './Contact.css';
import { Link } from 'react-router-dom'


const Contact = () => {
    const whatsappLink = "https://wa.me/+919989368468"; 
    const emailLink = "mailto:saikrishna@rewaoverseas.com"; 
    const instagramLink = "https://www.instagram.com/RewaOverseas/";

    const shoot = () => {
      window.scrollTo(0, 0)
    }

  return (<>
     <div className="study-abroad-banner">
      <h1>Start your study abroad journey today</h1>
      <p>
        Our team of expert counselors is on-hand to help you to choose the right path for you.
      </p>
      <Link to="/contact"><button className="chat-button" onClick={shoot}>Book Your Slot Now</button></Link>
    </div>
    <div className="container">
      <h1 className="con-heading">Time to speak about your options?</h1>
      <p>Get in touch with the REWA Educational counseling team to arrange a chat.</p>
      <div className="button-container">
        <a href={whatsappLink} className="button">WhatsApp</a>
        <a href={emailLink} className="button">EMAIL US</a>
        <a href={instagramLink} className="button">Instagram</a>
      </div>
    </div>
    
  </>);
};

export default Contact;
