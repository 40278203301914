import React from 'react';
import './Navbar.css';
import { Link } from 'react-router-dom';
import { FaAngleDown } from "react-icons/fa";
import { GiHamburgerMenu } from "react-icons/gi";
import { RxCross2 } from "react-icons/rx";
import img from "./images/REWA-logo.png";
import FooterBar from './FooterBar';

export default function Navbar() {
    
    const shoot = () => {
        window.scrollTo(0, 0);
    };
    const down1 = () => {
        window.scrollTo(0, 450);
    };
    const down2 = () => {
        window.scrollTo(0, 1000);
    };
    const down3 = () => {
        window.scrollTo(0, 1300);
    };

    // Function to close the menu
    const closeMenu = () => {
        document.getElementById('check').checked = false;
    };

    return (
        <>
            <FooterBar />
            <header className='header-nav'>
                <div>
                    <h1 className='logo-div'>
                        <Link to="/" onClick={shoot}><img className='REWA-logo' src={img} alt="REWA Logo" /></Link>
                    </h1>
                </div>

                <div className="lists-nav">
                    <input type="checkbox" id="check" />
                    <label htmlFor="check">
                        <GiHamburgerMenu id="btn" />
                        <RxCross2 id="cancel" />
                    </label>
                    <div className='header-links-nav'>
                        <ul>
                            <li className='li1'>
                                <Link to="/" onClick={() => { shoot(); closeMenu(); }}>Home</Link>
                            </li>
                        </ul>
                        <ul>
                            <div className="dropdown-nav">
                                <li className='li1'>
                                    <Link to="/about" onClick={() => { shoot(); closeMenu(); }}>About Us</Link>
                                </li>
                                {/* <div className="dropdown-content-nav">
                                    <div className='innerdiv'>
                                        <Link to="/about" onClick={() => { down1(); closeMenu(); }}><p>Who We Are</p></Link>
                                    </div>
                                    <div className='innerdiv'>
                                        <Link to="/about" onClick={() => { down2(); closeMenu(); }}><p>How We Are Different</p></Link>
                                    </div>
                                    <div className='innerdiv'>
                                        <Link to="/about" onClick={() => { down3(); closeMenu(); }}><p>Meet Our Leadership Team</p></Link>
                                    </div>
                                </div> */}
                            </div>
                        </ul>
                        <ul>
                            <div className="dropdown-nav">
                                <li className='li2'>
                                    <span className='study-dest'>Study Destinations<FaAngleDown className='icon' /></span>
                                </li>
                                <div className="dropdown-content1-nav">
                                    <div className='innerdiv'><Link to="/usa" onClick={() => { shoot(); closeMenu(); }}><p>USA</p></Link></div>
                                    <div className='innerdiv'><Link to="/uk" onClick={() => { shoot(); closeMenu(); }}><p>UK</p></Link></div>
                                    <div className='innerdiv'><Link to="/canada" onClick={() => { shoot(); closeMenu(); }}><p>CANADA</p></Link></div>
                                    <div className='innerdiv'><Link to="/australia" onClick={() => { shoot(); closeMenu(); }}><p>AUSTRALIA</p></Link></div>
                                    <div className='innerdiv'><Link to="/europe" onClick={() => { shoot(); closeMenu(); }}><p>EUROPE</p></Link></div>
                                    <div className='innerdiv'><Link to="/new-zealand" onClick={() => { shoot(); closeMenu(); }}><p>NEW ZEALAND</p></Link></div>
                                </div>
                            </div>
                        </ul>
                       
                        <ul>
                            <Link to="/contact">
                                <button className='touchbtn-nav' onClick={() => { shoot(); closeMenu(); }}><span>Contact Us</span></button>
                            </Link>
                        </ul>
                    </div>
                </div>
            </header>
        </>
    );
}
