import React from 'react'
import "./Welcome.css"
import welcome from "./images/welcome.jpg"
import 'animate.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';



export default function Welcome() {

    useEffect(() => {
         AOS.init();
        }, [])
        AOS.init({
         duration: 400,
         once: false,
         });
    return (<><div className='welcome-container'>
        <div className='welcome-upper-part'>
            <div className='welcom-upper'>
                <h1 className='welcome-tag'>WELCOME TO  <span>REWA OVERSEAS</span></h1>
                <p className='welcome-para' data-aos="fade-up">Our goal is to provide free counselling, free admissions (wherever possible), and free visa application assistance, to help you achieve your dream of studying abroad, and choosing the right career path.</p>
            </div></div>
            <div className='welcome-lower'>
                <div className='lower-left'><div >
                <img  className='welcome-img' src={welcome}></img>
                </div>
                </div>
              
               <div className='lower-right'>
               <div >
                    <h1 className='about-head' data-aos="fade-left">About <span>REWA</span> Overseas Educational Consultants</h1>
                    <p className='about-para1' data-aos="fade-left">Aim High - Work with a Vision - Stay Focused - Achieve Your Dream - And be a Luminary to Many</p>
                    <p className='about-para2' data-aos="fade-left">REWA Overseas Educational Consultants is the one-stop solution for all your Study Abroad requirements. Our core activity is to offer comprehensive services to students and professionals from all parts of India, who wish to pursue their higher education in Foreign countries like USA, UK, Canada, Australia, Germany, Ireland, France and so on…</p>
                </div>
               </div>


            </div>

    </div>
        
        
    </>

    )
}



