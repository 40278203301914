import React from 'react'
// import ContactPage from './ContactPage'
// import contactImg from "./images/c2.jpg"
import "./ContactUS.css"
import Contact from './Contact'
import Form from './Form'
export default function ContactUS() {
  return (
  <>
  <div>
      {/* <div className='contact-img-div'>
     
     <img className='contactimg' src={contactImg}></img>
     <h2 className='contact-name' >Contact Us</h2>
   
     </div> */}
      <div className="contact-banner">
                    <h1 className='contact-banar-name'>Contact Us</h1>
                </div>
  
  </div>
  <div className='contact-container-div'>
    <div className='contact-info-left'>
    <div className="consultation-container">
      <h1 className='consultation-heading'>Ask Us Your Questions or Arrange a Consultation</h1>
      <p className='consultation-para'>
        Rewa Educational Consulting is very happy to be able to offer
        consultation to students in English, French, Portuguese, or Spanish.
      </p>
      <p className='consultation-para'>
        Please contact our head office 24 hours per day by email or using the
        form on this page.
      </p>
      <p className='consultation-para'>
        You can use our booking system to arrange a personal online
        consultation.
      </p>
      <p className='consultation-para'>
        WhatsApp and Online Chat are live during the hours below.
      </p>
      <p className='consultation-para'>
        Please allow us an appropriate time to reply to your query.
      </p>
    </div>
    </div>
    <div className='contact-info-center-box'>
      <Form/>
    </div>
    <div className='contact-info-right'></div>
  </div>
  
  <Contact/>
  {/* <ContactPage/> */}
  </>
  )
}
