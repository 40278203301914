import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './Services.css'; 
import ser1 from "./images/ser1.png";
import ser2 from "./images/ser2.png";
import ser3 from "./images/ser3.png";
import ser4 from "./images/ser4.png";
import ser5 from "./images/ser5.png";
import ser6 from "./images/ser6.png";
import ser7 from "./images/ser7.png";
import ser8 from "./images/ser8.png";
import ser9 from "./images/ser9.png";

export default function Services() {
  useEffect(() => {
    AOS.init({ duration: 500 });
  }, []);

  const services = [
    { name: "Profile Evaluation", image: ser1, path: "/contact" },
    { name: "Course Advice", image: ser2, path: "/contact" },
    { name: "Shortlisting Universities", image: ser3, path: "/contact" },
    { name: "Documentation Assistance", image: ser4, path: "/contact" },
    { name: "Application Process", image: ser5, path: "/contact" },
    { name: "Financing Assistance", image: ser6, path: "/contact" },
    { name: "Visa Guidance", image: ser7, path: "/contact" },
    { name: "Mock Interview", image: ser8, path: "/contact" },
    { name: "Predeparture Orientation", image: ser9, path: "/contact" }
  ];

  const shoot = () => {
    window.scrollTo(0, 0);
  };

  return (
    <>
      <div>
        <h1 className='services-headding' data-aos="fade-up">SERVICES</h1>
        <p className='services-para' data-aos="fade-up">Empowering Your Journey with Comprehensive Solutions.</p>
      </div>
      <div className="profile-evaluation">
        <div className="services-grid">
          {services.map((service, index) => {
            let animation = '';
            if (index % 3 === 0) animation = 'fade-left'; // Left column
            else if (index % 3 === 1) animation = 'fade-up'; // Center column
            else animation = 'fade-right'; // Right column

            return (
              <Link 
                to={service.path} 
                key={service.name} 
                onClick={shoot} 
                className="service-button"
                data-aos={animation}
              >
                <img src={service.image} className="service-image" alt={service.name} />
                <span className="service-title">{service.name}</span>
              </Link>
            );
          })}
        </div>
      </div>
    </>
  );
}
