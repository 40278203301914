
import React from 'react';
import './Europe.css';
import usa from "./images/europe1.jpg";
import Contact from './Contact';
// import usabanar from "./images/conban3.jpg"
// import ContactPage from './ContactPage';

const EUROPE = () => {
    return (
        <>
            <div className="country-container">
                <div className="europe-banner">
                    {/* <img className='eur-banar-img' src={usabanar}></img> */}
                    <h1 className='europe-banar-name'>EUROPE</h1>
                </div>
                <div className="country-info">
                    <h1 className='eur-tag'>WELCOME TO  <span>EUROPE</span></h1>
                    <p className='eur-para'>
                Europe, a continent of diverse cultures and economies, is a major hub for multinational companies across various industries. Its strong regulatory frameworks and skilled labor force attract significant foreign investment. Key cities like Frankfurt, Paris, and Amsterdam serve as vital business centers for international operations.

                    </p>
                </div>
                <div className='eur-info'>
                    <h1 className='eur-info-tag'>Study in EUROPE</h1>
                    <ul>
                        <li>EUROPE is an educational hub with over 4500 institutions offering undergraduate, postgraduate and doctorate degrees </li>
                        <li>Degrees which pack world-recognition at the most elevated level</li>
                        <li>Excellent support system for international students</li>
                        <li> EUROPE provides phenomenally higher returns over a student's investment. EUROPE education is the best investment for the future</li>
                        <li> EUROPE pride themselves on being at the forefront of technology, research and techniques as they make the best possible equipment and resources available to their students.</li>
                        <li> A preference for extrinsic workforce from multicultural backgrounds.</li>
                        <li> Affordable tuition fees ranging from $15,000 and $60,000 per annum.</li>
                        <li> Offers financial help in the form of scholarships, emphasis on internships & career advancement</li>
                        <li> Since the EUROPE lives in the lap of technological advancement and industrial as well as sectoral growth, obtaining employment opportunities is a matter of just finding the right one!</li>
                        <li> One can easily overcome culture shock in the EUROPE and as you look around, you’ll see you’re not the only newcomer in town</li>
                    </ul>
                </div>

                <div className='eur-img-info'>
                    <div className='eur-left-img-part'>
                    <img className='eur-down-img' src={usa}></img>
                    </div>
                    <div className='eur-right-info-part'>
                    <h1 className='about-head-eur'><span>REWA</span> Overseas Educational Consultants</h1>
                    <p className='about-para1-eur'>Aim High - Work with a Vision - Stay Focused - Achieve Your Dream - And be a Luminary to Many</p>
                    <p className='about-para2-eur'>REWA Overseas Educational Consultants is the one-stop solution for all your Study Abroad requirements. Our core activity is to offer comprehensive services to students and professionals from all parts of India, who wish to pursue their higher education in Foreign countries like USA, UK, Canada, Australia, Europe, New zealand and so on…</p>
                
                    </div>


                </div>
            </div>
            <Contact/>
        </>
    );
};

export default EUROPE;
