import React, { useState, useEffect } from "react";
import { FaUserGraduate } from "react-icons/fa";
import { FaPeopleGroup, FaMapLocationDot, FaSchool } from "react-icons/fa6";
import { FaPassport } from "react-icons/fa";


import "./OurImpact.css";

export default function OurImpact() {
    const [count853, setCount853] = useState(0);
    const [count35, setCount35] = useState(0);
    const [count55, setCount55] = useState(0);
    const [count85, setCount85] = useState(0);
    const [count92, setCount92] = useState(0); // New state for Visa Accuracy
    const [animationStarted, setAnimationStarted] = useState(false);

    useEffect(() => {
        const target853 = 1200.1;
        const target35 = 40.1;
        const target55 = 55.1;
        const target85 = 450.1;
        const target92 = 92.1; // New target for Visa Accuracy
        const animationDuration = 5000; // Duration in milliseconds (5 seconds)
        const steps853 = target853 / (animationDuration / 100);
        const steps35 = target35 / (animationDuration / 100);
        const steps55 = target55 / (animationDuration / 100);
        const steps85 = target85 / (animationDuration / 100);
        const steps92 = target92 / (animationDuration / 100); // Step for Visa Accuracy

        const handleScroll = () => {
            const element = document.querySelector('.snip1533-sst');
            if (!animationStarted && isElementInViewport(element)) {
                setAnimationStarted(true);

                let intervalId853, intervalId35, intervalId55, intervalId85, intervalId92;

                const animateCount853 = () => {
                    intervalId853 = setInterval(() => {
                        setCount853((prevCount) => {
                            const nextCount = prevCount + steps853;
                            return nextCount >= target853 ? target853 : nextCount;
                        });
                    }, 100);
                };
                const animateCount85 = () => {
                    intervalId85 = setInterval(() => {
                        setCount85((prevCount) => {
                            const nextCount = prevCount + steps85;
                            return nextCount >= target85 ? target85 : nextCount;
                        });
                    }, 100);
                };
                const animateCount55 = () => {
                    intervalId55 = setInterval(() => {
                        setCount55((prevCount) => {
                            const nextCount = prevCount + steps55;
                            return nextCount >= target55 ? target55 : nextCount;
                        });
                    }, 100);
                };
                const animateCount35 = () => {
                    intervalId35 = setInterval(() => {
                        setCount35((prevCount) => {
                            const nextCount = prevCount + steps35;
                            return nextCount >= target35 ? target35 : nextCount;
                        });
                    }, 100);
                };
                const animateCount92 = () => { // Animation for Visa Accuracy
                    intervalId92 = setInterval(() => {
                        setCount92((prevCount) => {
                            const nextCount = prevCount + steps92;
                            return nextCount >= target92 ? target92 : nextCount;
                        });
                    }, 100);
                };

                // Start counting animations
                animateCount853();
                animateCount35();
                animateCount55();
                animateCount85();
                animateCount92();

                // Clean up intervals when component unmounts or animation is completed
                setTimeout(() => {
                    clearInterval(intervalId853);
                    clearInterval(intervalId35);
                    clearInterval(intervalId55);
                    clearInterval(intervalId85);
                    clearInterval(intervalId92);
                }, animationDuration);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [animationStarted]);

    const isElementInViewport = (el) => {
        if (!el) return false;
        const rect = el.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
        );
    };

    return (
        <>
            <div><h1 className='impact-headding'>OUR IMPACT</h1></div>
            <div>
                <div className="our-impact-main">
                    <div className="our-impact-sub">
                        <figure className="snip1533-sst">
                            <div><FaUserGraduate className='count-icons' /></div>
                            <div>
                                <h3 className='counting'><span>{Math.floor(count85)}+</span></h3>
                                <h2 className='counting-name'>Graduates</h2>
                            </div>
                        </figure>
                    </div>
                    <div className="our-impact-sub">
                        <figure className="snip1533-sst">
                            <div><FaPeopleGroup className='count-icons' /></div>
                            <div>
                                <h3 className='counting'><span>{Math.floor(count853)}+</span></h3>
                                <h2 className='counting-name'>Students Impacted</h2>
                            </div>
                        </figure>
                    </div>
                    <div className="our-impact-sub">
                        <figure className="snip1533-sst">
                            <div><FaMapLocationDot className='count-icons' /></div>
                            <div>
                                <h3 className='counting'><span>{Math.floor(count35)}+</span></h3>
                                <h2 className='counting-name'>Countries</h2>
                            </div>
                        </figure>
                    </div>
                    <div className="our-impact-sub">
                        <figure className="snip1533-sst">
                            <div><FaSchool className='count-icons' /></div>
                            <div>
                                <h3 className='counting'><span>{Math.floor(count55)}+</span></h3>
                                <h2 className='counting-name'>Schools Represented</h2>
                            </div>
                        </figure>
                    </div>
                    {/* New Visa Accuracy Section */}
                    <div className="our-impact-sub">
                        <figure className="snip1533-sst">
                        <div><FaPassport className='count-icons' /></div>
                        <div>
                                <h3 className='counting'><span>{Math.floor(count92)}%</span></h3>
                                <h2 className='counting-name'>Visa Accuracy</h2>
                            </div>
                        </figure>
                    </div>
                </div>
            </div>
        </>
    );
}
