import React, { useState, useEffect } from 'react';
import './Uk.css';
import usa from "./images/uk.jpg";
// import usabanar from "./images/conban.jpg";
// import ContactPage from './ContactPage';
import Contact from './Contact';


const UK = () => {
   
    return (
        <>
            <div className="country-container">
                <div className="uk-banner">
                    {/* <img className='usa-banar-img' src={usabanar} alt="UK Banner" /> */}
                    <h1 className='uk-banar-name'>UK</h1>
                </div>
                <div className="country-info">
                    <h1 className='uk-tag'>WELCOME TO  <span>UK</span></h1>
                    <p className='uk-para'>
                        The UK boasts a strong presence of overseas companies, especially in finance, technology, and creative sectors. London is a major international business hub, attracting firms from around the globe with its regulatory framework and talent pool. The country's historical ties and cultural richness further enhance its appeal.
                    </p>
                </div>
                <div className='uk-info'>
                    <h1 className='uk-info-tag'>Study in UK</h1>
                    <ul>
                        <li>USA is an educational hub with over 4500 institutions offering undergraduate, postgraduate and doctorate degrees.</li>
                        <li>Degrees which pack world-recognition at the most elevated level.</li>
                        <li>Excellent support system for international students.</li>
                        <li>UK provides phenomenally higher returns over a student's investment. UK education is the best investment for the future.</li>
                        <li>UK pride themselves on being at the forefront of technology, research and techniques as they make the best possible equipment and resources available to their students.</li>
                        <li>A preference for extrinsic workforce from multicultural backgrounds.</li>
                        <li>Affordable tuition fees ranging from $15,000 and $60,000 per annum.</li>
                        <li>Offers financial help in the form of scholarships, emphasis on internships & career advancement.</li>
                        <li>Since the UK lives in the lap of technological advancement and industrial as well as sectoral growth, obtaining employment opportunities is a matter of just finding the right one!</li>
                        <li>One can easily overcome culture shock in the UK and as you look around, you’ll see you’re not the only newcomer in town.</li>
                    </ul>
                </div>

                <div className='uk-img-info'>
                    <div className='uk-left-img-part'>
                        <img className='uk-down-img' src={usa} alt="UK" />
                    </div>
                    <div className='uk-right-info-part'>
                        <h1 className='about-head-uk'><span>REWA</span> Overseas Educational Consultants</h1>
                        <p className='about-para1-uk'>Aim High - Work with a Vision - Stay Focused - Achieve Your Dream - And be a Luminary to Many</p>
                        <p className='about-para2-uk'>REWA Overseas Educational Consultants is the one-stop solution for all your Study Abroad requirements. Our core activity is to offer comprehensive services to students and professionals from all parts of India, who wish to pursue their higher education in foreign countries like USA, UK, Canada, Australia, Europe, New Zealand and so on…</p>
                    </div>
                </div>
            </div>
            <Contact />
        </>
    );
};

export default UK;
