import React, { useState } from 'react';
import './Accordion.css'; // Import your CSS file

const AccordionItem = ({ title, content, isOpen, onToggle, isActive }) => {
  return (
    <div className={`accordion-item ${isActive ? 'active' : ''}`}>
      <div onClick={onToggle} className="accordion-header">
        <span>{title}</span>
        <span className="accordion-toggle">{isOpen ? '−' : '+'}</span>
      </div>
      <div
        className={`accordion-content ${isOpen ? 'open' : ''}`}
        style={{
          maxHeight: isOpen ? '300px' : '0',
          opacity: isOpen ? 1 : 0,
          transition: 'max-height 0.5s ease, opacity 0.5s ease',
          overflow: 'hidden',
        }}
      >
        <ul>
          {content.map((point, index) => (
            <li key={index}>{point}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const Accordion = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const items = [
    {
      title: 'What services do you offer ?',
      content: ['We provide educational counseling to help students select the best courses and institutions.','Our team assists with application processing to ensure all documents meet university or job requirements.','We offer visa guidance and career counseling for those aiming to study, work, or settle abroad.'
],
    },
    {
      title: 'What countries do you provide services for ?',
      content: ['We cover a range of popular destinations, including the USA, Canada, UK, Australia, Germany, and New Zealand.','Our consultancy also supports applications to several other top study and work destinations.','We stay updated on each country’s requirements to ensure smooth transitions for our clients.' ],
    },
    {
      title: 'How much do your services cost ?',
      content: ['Our fees vary based on the country and the level of support needed.','We offer personalized consultations to understand individual needs before providing a price quote.','This ensures cost-effective and tailored solutions for every client.'],
    },
    {
      title: 'Why should I choose your consultancy ?',
      content: ['We have a track record of success with over 50 satisfied clients.','Our team provides expert advice and personalized service for each step of your journey.','We prioritize high success rates to help you achieve your international goals effectively.'],
    },
   
  ];

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };


  return (
   <>
   <div>
   <h1 className='faqs-headding'>FAQS</h1>

   <div className="accordion">
      {items.map((item, index) => (
        <AccordionItem
          key={index}
          title={item.title}
          content={item.content}
          isOpen={openIndex === index}
          onToggle={() => handleToggle(index)}
          isActive={openIndex === index} // Check if this item is active
        />
      ))}
    </div>
   </div>
   </>
  );
};

export default Accordion;
