
import React from 'react';
import './Usa.css';
import usa from "./images/usa1.jpg";
// import usabanar from "./images/usa-banar.jpg"
import Contact from './Contact';

const USA = () => {
    return (
        <>
            <div className="country-container">
                <div className="usa-banner">
                    {/* <img className='usa-banar-img' src={usabanar}></img> */}
                    <h1 className='Usa-banar-name'>USA</h1>
                </div>
                <div className="country-info">
                    <h1 className='usa-tag'>WELCOME TO  <span>USA</span></h1>
                    <p className='usa-para'>
                        The United States of America is a vast and diverse country located in North America.
                        Known for its rich history, stunning landscapes, and vibrant culture, the USA offers
                        something for everyone. From the skyscrapers of New York City to the natural wonders
                        of the Grand Canyon, explore the many facets of this incredible nation.
                    </p>
                </div>
                <div className='usa-info'>
                    <h1 className='usa-info-tag'>Study in USA</h1>
                    <ul>
                        <li>USA is an educational hub with over 4500 institutions offering undergraduate, postgraduate and doctorate degrees </li>
                        <li>Degrees which pack world-recognition at the most elevated level</li>
                        <li>Excellent support system for international students</li>
                        <li> USA provides phenomenally higher returns over a student's investment. USA education is the best investment for the future</li>
                        <li> USA pride themselves on being at the forefront of technology, research and techniques as they make the best possible equipment and resources available to their students.</li>
                        <li> A preference for extrinsic workforce from multicultural backgrounds.</li>
                        <li> Affordable tuition fees ranging from $15,000 and $60,000 per annum.</li>
                        <li> Offers financial help in the form of scholarships, emphasis on internships & career advancement</li>
                        <li> Since the USA lives in the lap of technological advancement and industrial as well as sectoral growth, obtaining employment opportunities is a matter of just finding the right one!</li>
                        <li> One can easily overcome culture shock in the USA and as you look around, you’ll see you’re not the only newcomer in town</li>
                    </ul>
                </div>

                <div className='usa-img-info'>
                    <div className='usa-left-img-part'>
                    <img className='usa-down-img' src={usa}></img>
                    </div>
                    <div className='usa-right-info-part'>
                    <h1 className='about-head-usa'><span>REWA</span> Overseas Educational Consultants</h1>
                    <p className='about-para1-usa'>Aim High - Work with a Vision - Stay Focused - Achieve Your Dream - And be a Luminary to Many</p>
                    <p className='about-para2-usa'>REWA Overseas Educational Consultants is the one-stop solution for all your Study Abroad requirements. Our core activity is to offer comprehensive services to students and professionals from all parts of India, who wish to pursue their higher education in Foreign countries like USA, UK, Canada, Australia, Europe, New zealand and so on…</p>
                
                    </div>


                </div>
            </div>
            <Contact/>
        </>
    );
};

export default USA;
