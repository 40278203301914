import React from 'react'
// import HomeCarousel from './HomeCarousel'
import OurImpact from './OurImpact'
import Welcome from './Welcome'
import Accordion from './Accordion'
// import ContactPage from './ContactPage'
import CardContainer from './CardContainer'
import Services from './Services'
import University from './University'
import Contact from './Contact'
import './Slider.css';
import SliderComponent from './SliderComponent'

export default function HomePage() {
  return (
    <div>
      {/* <HomeCarousel/> */}
      <SliderComponent/>
      <Welcome/>
      <CardContainer/>
      <University/>
      <Accordion/>
   <OurImpact/>
   <Services/>
   <Contact/>
    </div>
  )
}
