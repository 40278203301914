import React, { useState } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
import "./Form.css"


import emailjs from 'emailjs-com';

// import { IoCallOutline  } from "react-icons/io5";
import { MdOutlineMail } from "react-icons/md";
// import { MdOutlinePermPhoneMsg } from "react-icons/md";


export default function Form() {

  useEffect(() => {
    AOS.init();
  }, [])
  AOS.init({
    duration: 400,
    once: false,
  });

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    country: '',
    message: '',
  });
  const [error1, setError1] = useState('');
  const [error2, setError2] = useState('');
  const [status, setStatus] = useState('');

  const countries = ['United States', 'Canada', 'United Kingdom', 'Australia'];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setError1(''); // Clear error when the user changes input
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate phone number
    const phonePattern = /^\d{10}$/; // Regex for exactly 10 digits
    if (!phonePattern.test(formData.phone)) {
      setError1('Phone number must be exactly 10 digits.');
      return;
    }
    //validat email
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email validation regex
    if (!emailPattern.test(formData.email)) {
      setError2('Please enter a valid email address.');
      return;
    }

    // Send email
    emailjs.send("service_2kqtpc6", "template_n9q1nre", formData, 'QLxBAKWgqkdXPtHcL')
      .then((response) => {
        console.log('Email sent successfully!', response.status, response.text);

        // Reset the form
        setFormData({
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          country: '',
          message: '',
        });
        alert('Message sent successfully!');

        setStatus('Message sent successfully!');
      })
      .catch((err) => {
        console.error('Failed to send email. Error:', err);
        setStatus('Failed to send message. Please try again.');
      });
  };




  return (<>



    <div className='contact-form-div'>
      <div className='form-div'>
        <h2 className='form-headding'>< MdOutlineMail className='msg-icon' />GET IN TOUCH
        </h2>
        {/* {error && <div style={{ color: 'red' }}>{error}</div>} */}
        <form className='contact-form' onSubmit={handleSubmit}>
          <div className='label-name'>
            <label>First Name:</label>
            <input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              required
            />
          </div>
          <div className='label-name'>
            <label>Last Name:</label>
            <input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              required
            />
          </div>
          <div className='label-name'>
            <label>Email:</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            {error2 && <div style={{ color: 'red' }}>{error2}</div>}
          </div>
          <div className='label-name'>
            <label>Phone Number:</label>

            <input
              type="tel"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              required
            />
            {error1 && <div style={{ color: 'red' }}>{error1}</div>}

          </div>
          <div className="label-name">
            <label>Country:</label>
            <select
              name="country"
              value={formData.country}
              onChange={handleChange}
              required
              className="select-country"
            >
              <option value="">Select Country</option>
              {countries.map((country, index) => (
                <option key={index} value={country}>{country}</option>
              ))}
            </select>
          </div>
          <div className='label-name'>
            <label>Message:</label>
            <textarea className='text-box'
              name="message"
              placeholder='Description'
              value={formData.message}
              onChange={handleChange}
              required
            />
          </div>
          {/* {status && <p>{status}</p>} */}
          <button className='submit-botton' type="submit">Submit</button>
        </form>

      </div>

    </div>


  </>

  )
}
