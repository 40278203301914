// SliderComponent.jsx
import React, { useState } from 'react';
import Slider from 'react-slick';
// import { TypeAnimation } from 'react-type-animation';
import { Link } from 'react-router-dom'
import { Fade } from 'react-awesome-reveal';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

// Import images
import image1 from './images/conban.jpg';
import image2 from './images/conban5.jpeg';

const SliderComponent = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    pauseOnHover: false,
    autoplaySpeed: 4000,
    arrows: true,
   
    beforeChange: (oldIndex, newIndex) => setCurrentSlide(newIndex),
  };

  const slides = [
   
    {
      imageUrl: image2,
      title: "REWA",
      subtitle: "OVERSEAS EDUCATION CONSULTANTS",
      description: "Your Gateway to Overseas Education Excellence.",
      buttonText: "Contact Now",
    },
    {
      imageUrl: image1,
      title: "GLOBAL STUDY",
      subtitle: "ABROAD QUALIFIED & EXPERIENCED TEAM",
      buttonText: "Contact Us",
    },
  ];

  return (
    <div className="slider-container">
      <Slider {...settings}>
        {slides.map((slide, index) => (
          <div key={index} className="slide">
            <div 
              className="slide-background" 
              style={{ 
                backgroundImage: `url(${slide.imageUrl})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                height: '550px',
                width: "100%",
               
              }}>
              <div className={`slide-content slide-${index + 1}`}>
                {index === currentSlide && (
                  <Fade cascade triggerOnce duration={1000}>
                   
                    <h2>{slide.title}</h2>
                    <p>{slide.subtitle}</p>
                    {slide.description && <p>{slide.description}</p>}
                    <Link to="/contact"><button>{slide.buttonText}</button></Link>
                  </Fade>
                )}
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default SliderComponent;
