import React from 'react';
import './FooterBar.css'; 
import { Link } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faYoutube, faTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons';

const FooterBar = () => {
  const phoneNumber1 = "+91 99893 68468";
  const phoneNumber2 = "+91 99893 68468";
  const emailAddress = " saikrishna@rewaoverseas.com";
  
  const whatsappLink = `https://wa.me/${phoneNumber1.replace(/\s+/g, '')}`; // Format for WhatsApp
  const mailtoLink = `mailto:${emailAddress}`; // Format for email

  return (
    <div className="oneline-footer-baar">
      <div className="foot-container">
        <div className="footerbar-social-icons">
          <a href="https://www.facebook.com/rewaOverseas" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faFacebook} className="footbar-icon" />
          </a>
          <a href="https://www.instagram.com/rewaoverseas/" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faInstagram} className="footbar-icon" />
          </a>
          <a href="https://www.youtube.com/" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faYoutube} className="footbar-icon" />
          </a>
          <a href="https://twitter.com/" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faTwitter} className="footbar-icon" />
          </a>
          <a href="https://www.linkedin.com/" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faLinkedin} className="footbar-icon" />
          </a>
        </div>

        <p className="footer-contact-info">
          <a href={whatsappLink} className="phone" target="_blank" rel="noopener noreferrer">
            {phoneNumber1}, {phoneNumber2}
          </a> | 
          <a href={mailtoLink} className="email">
            {emailAddress}
          </a>
        </p>

        <div className="footer-contact-button">
         <Link to='/contact'> <a href="contact.html" className="btn">Contact Us</a></Link>
        </div>
      </div>
    </div>
  );
};

export default FooterBar;
