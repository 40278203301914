import React from 'react';
import uv1 from "./images/uv1.jpg";
import uv2 from "./images/uv2.jpg";
import uv3 from "./images/uv3.jpg";
import uv4 from "./images/uv4.jpg";
import uv5 from "./images/uv5.jpg";
import uv6 from "./images/uv6.jpg";
import uv7 from "./images/uv7.jpg";
import uv8 from "./images/uv8.jpg";
import uv9 from "./images/uv9.jpg";
import uv10 from "./images/uv10.jpg";
import './University.css'; // Import your CSS file

function University() {
    const OurUniversity = [
        uv1, uv2, uv3, uv4, uv5, uv6, uv7, uv8, uv9, uv10
    ];

    return (
        <div className="marquee-container">
             <div>
      <h1 className='university-heading' >Our Universitys</h1>
      <p className="university-description" data-aos="fade-up"> Welcome to our esteemed university! We are dedicated to provide quality education and fostering a vibrant learning environment.
      </p>

      </div>
            <marquee behavior="alternate">
                {OurUniversity.map((icon, index) => (
                    <img key={index} src={icon} alt={`University ${index}`} className="marquee-image" />
                ))}
            </marquee>
        </div>
    );
}

export default University;
