
import React from 'react';
import './Canada.css';
import canada from "./images/canada.jpg";
import Contact from './Contact';
// import usabanar from "./images/banimg.jpg"
// import ContactPage from './ContactPage';

const CANADA = () => {
    return (
        <>
            <div className="country-container">
                <div className="canada-banner">
                    {/* <img className='canada-banar-img' src={usabanar}></img> */}
                    <h1 className='canada-banar-name'>CANADA</h1>
                </div>
                <div className="country-info">
                    <h1 className='canada-tag'>WELCOME TO  <span>CANADA</span></h1>
                    <p className='canada-para'>
                    Canada is known for its welcoming attitude toward overseas companies, particularly in sectors like natural resources, technology, and manufacturing. Its stable economy, skilled workforce, and proximity to the US market make it an attractive destination for foreign investment. Initiatives to foster innovation also contribute to its business-friendly environment.
                    </p>
                </div>
                <div className='canada-info'>
                    <h1 className='canada-info-tag'>Study in CANADA</h1>
                    <ul>
                        <li>CANADA is an educational hub with over 4500 institutions offering undergraduate, postgraduate and doctorate degrees </li>
                        <li>Degrees which pack world-recognition at the most elevated level</li>
                        <li>Excellent support system for international students</li>
                        <li> CANADA provides phenomenally higher returns over a student's investment. CANADA education is the best investment for the future</li>
                        <li> CANADA pride themselves on being at the forefront of technology, research and techniques as they make the best possible equipment and resources available to their students.</li>
                        <li> A preference for extrinsic workforce from multicultural backgrounds.</li>
                        <li> Affordable tuition fees ranging from $15,000 and $60,000 per annum.</li>
                        <li> Offers financial help in the form of scholarships, emphasis on internships & career advancement</li>
                        <li> Since the CANADA lives in the lap of technological advancement and industrial as well as sectoral growth, obtaining employment opportunities is a matter of just finding the right one!</li>
                        <li> One can easily overcome culture shock in the CANADA and as you look around, you’ll see you’re not the only newcomer in town</li>
                    </ul>
                </div>

                <div className='canada-img-info'>
                    <div className='canada-left-img-part'>
                    <img className='canada-down-img' src={canada}></img>
                    </div>
                    <div className='canada-right-info-part'>
                    <h1 className='about-head-canada'><span>REWA</span> Overseas Educational Consultants</h1>
                    <p className='about-para1-canada'>Aim High - Work with a Vision - Stay Focused - Achieve Your Dream - And be a Luminary to Many</p>
                    <p className='about-para2-canada'>REWA Overseas Educational Consultants is the one-stop solution for all your Study Abroad requirements. Our core activity is to offer comprehensive services to students and professionals from all parts of India, who wish to pursue their higher education in Foreign countries like USA, UK, Canada, Australia, Europe, New zealand and so on…</p>
                
                    </div>


                </div>
            </div>
            <Contact/>
        </>
    );
};

export default CANADA;
