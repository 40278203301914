
import React from 'react';
import './Newzealand.css';
import usa from "./images/new-zealand.jpg";
import Contact from './Contact';
// import usabanar from "./images/usa-banar.jpg"
// import ContactPage from './ContactPage';

const Newzealand = () => {
    return (
        <>
            <div className="country-container">
                <div className="new-banner">
                    {/* <img className='usa-banar-img' src={usabanar}></img> */}
                    <h1 className='new-banar-name'>NEW ZEALAND</h1>
                </div>
                <div className="country-info">
                    <h1 className='new-tag'>WELCOME TO  <span>NEW ZEALAND</span></h1>
                    <p className='new-para'>
                    New Zealand is known for its beautiful landscapes and innovative spirit, making it an attractive location for overseas companies in agriculture, technology, and tourism. The country offers a stable economy and a business-friendly regulatory environment. Its focus on sustainability and creativity fosters strong international partnerships.


                    </p>
                </div>
                <div className='new-info'>
                    <h1 className='new-info-tag'>Study in NEW ZEALAND</h1>
                    <ul>
                        <li>NEW ZEALAND is an educational hub with over 4500 institutions offering undergraduate, postgraduate and doctorate degrees </li>
                        <li>Degrees which pack world-recognition at the most elevated level</li>
                        <li>Excellent support system for international students</li>
                        <li> NEW ZEALAND provides phenomenally higher returns over a student's investment. NEW ZEALAND education is the best investment for the future</li>
                        <li> NEW ZEALAND pride themselves on being at the forefront of technology, research and techniques as they make the best possible equipment and resources available to their students.</li>
                        <li> A preference for extrinsic workforce from multicultural backgrounds.</li>
                        <li> Affordable tuition fees ranging from $15,000 and $60,000 per annum.</li>
                        <li> Offers financial help in the form of scholarships, emphasis on internships & career advancement</li>
                        <li> Since the NEW ZEALAND lives in the lap of technological advancement and industrial as well as sectoral growth, obtaining employment opportunities is a matter of just finding the right one!</li>
                        <li> One can easily overcome culture shock in the NEW ZEALAND and as you look around, you’ll see you’re not the only newcomer in town</li>
                    </ul>
                </div>

                <div className='new-img-info'>
                    <div className='new-left-img-part'>
                    <img className='new-down-img' src={usa}></img>
                    </div>
                    <div className='new-right-info-part'>
                    <h1 className='about-head-new'><span>REWA</span> Overseas Educational Consultants</h1>
                    <p className='about-para1-new'>Aim High - Work with a Vision - Stay Focused - Achieve Your Dream - And be a Luminary to Many</p>
                    <p className='about-para2-new'>REWA Overseas Educational Consultants is the one-stop solution for all your Study Abroad requirements. Our core activity is to offer comprehensive services to students and professionals from all parts of India, who wish to pursue their higher education in Foreign countries like USA, UK, Canada, Australia, Europe, New zealand and so on…</p>
                
                    </div>


                </div>
            </div>
            <Contact/>
        </>
    );
};

export default Newzealand;
