import React from 'react';
import './Ourservices.css'; // Add your styles here
// import ourbanar from './images/service-banner.jpg';
import rightImage from './images/our-team.jpg';
// import founderImage from './images/ceo.jpg';
// import ContactPage from './ContactPage';
import Contact from './Contact';

const Ourservices = () => {
    
    return (<>
        <div>
        <div className="our-banner">
                    {/* <img className='our-banar-img' src={ourbanar} alt="UK Banner" /> */}
                    <h1 className='our-banar-name'>About Us</h1>
                </div>


            <div className="how-we-are-container">
                <div className="our-info-left">
                    <h2 className='our-headding'>WHO WE ARE</h2>
                    <p className='our-para'>
                    At REWA, we are a team of passionate education consultants dedicated to transforming the dreams of international students into reality. With years of experience in the field, we specialize in providing personalized guidance to help students navigate the complex journey of studying abroad.
                    </p>
                    <p className='our-para'>
                    Our diverse team comprises experts from various backgrounds, including seasoned counselors who have lived and studied in the United States. We understand the challenges and opportunit
                    </p>
                    <p className='our-para'>
                        Our values include integrity, teamwork, and excellence. We believe in the power of collaboration
                        to achieve great results.
                    </p>
                </div>
                <div className="our-image-right">
                    <img src={rightImage} alt="Team" className="team-image" />
                </div>
            </div>
            <div className='our-info'>
                <h1 className='our-info-tag'>What makes us different</h1>
                <ul>
                    <li> Live experience of studying & working abroad </li>
                    <li> Vast experience in Overseas Education field</li>
                    <li>Providing Quality, Unbiased and Transparent services</li>
                    <li> Understanding the exact student preferences and processing low to high profile candidates with the best fit options</li>
                    <li> Responsive staff who are available on calls, whatsapp & emails</li>
                </ul>
            </div>
           
        </div>
        <Contact/>
    </>);
};

export default Ourservices;
