import React from 'react';
import './Footer.css'
import footimg from "./images/REWA-logo-white.png"
import { Link } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faYoutube, faTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons';
const Footer = () => {
  const shoot = () => {
    window.scrollTo(0, 0)
}
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section about">
        <Link to="/" ><img className='REWA-logo-foot' onClick={shoot} src={footimg}></img></Link>

          <p className='foot-logo-tag'>THE KEY TO SUCCESS </p>
        </div>
        <div className="footer-section about">
          <h2 className='footer-heading-tag'>About Us</h2>
          <p>
            We are an overseas education consultancy dedicated to helping students achieve their dreams of studying abroad.
          </p>
        </div>
        <div className="footer-section contact">
          <h2 className='footer-heading-tag'>Contact Us</h2>
          <p>Email: saikrishna@rewaoverseas.com</p>
          <p>Phone: +91 99893 68468</p>
        </div>
        <div className="footer-section social">
          <h2 className='footer-heading-tag'>Follow Us</h2>
          <div className="footer-social-icons">
          <a href="https://www.facebook.com/RewaOverseas/" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faFacebook} className="foot-icon" />
          </a>
          <a href="https://www.instagram.com/rewaoverseas/" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faInstagram} className="foot-icon" />
          </a>
          <a href="https://www.youtube.com/" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faYoutube} className="foot-icon" />
          </a>
          <a href="https://twitter.com/" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faTwitter} className="foot-icon" />
          </a>
          <a href="https://www.linkedin.com/" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faLinkedin} className="foot-icon" />
          </a>
        </div>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; {new Date().getFullYear()} REWA Overseas Company. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
